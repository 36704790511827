/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetLessorUserMeResponseLessorUser,
    GetLessorUserMeResponseLessorUserFromJSON,
    GetLessorUserMeResponseLessorUserFromJSONTyped,
    GetLessorUserMeResponseLessorUserToJSON,
} from './GetLessorUserMeResponseLessorUser';

/**
 * 
 * @export
 * @interface GetLessorUsersResponse
 */
export interface GetLessorUsersResponse {
    /**
     * 
     * @type {Array<GetLessorUserMeResponseLessorUser>}
     * @memberof GetLessorUsersResponse
     */
    lessorUsers: Array<GetLessorUserMeResponseLessorUser>;
}

export function GetLessorUsersResponseFromJSON(json: any): GetLessorUsersResponse {
    return GetLessorUsersResponseFromJSONTyped(json, false);
}

export function GetLessorUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLessorUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lessorUsers': ((json['lessorUsers'] as Array<any>).map(GetLessorUserMeResponseLessorUserFromJSON)),
    };
}

export function GetLessorUsersResponseToJSON(value?: GetLessorUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lessorUsers': ((value.lessorUsers as Array<any>).map(GetLessorUserMeResponseLessorUserToJSON)),
    };
}

